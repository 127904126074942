import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import Grid from "@material-ui/core/Grid";

const SearchFields = ({
  searchBar,
  Fields,
  handleSearchClose,
  handleSearchOpen,
}) => {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => handleSearchClose()}
      >
        <Grid style={{ width: "30%", marginRight:"25px" }}>
          <TextField
            onClick={() => handleSearchOpen()}
            style={{ display: "flex" }}
            onChange={() => {}}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Search"
          />

          <Collapse
            in={searchBar}
            timeout="auto"
            unmountOnExit
            className={classes.overLayBox}
            style={{width:'26.5%'}}
          >
            <Fields />
          </Collapse>
        </Grid>
      </ClickAwayListener>
    </>
  );
};

SearchFields.propTypes = {
  searchBar: PropTypes.bool,
  Fields: PropTypes.any,
  handleSearchClose: PropTypes.func,
  handleSearchOpen: PropTypes.func,
};

export default SearchFields;