import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";
import CryptoJS from "crypto-js";

const genrateOfferPatient = (
  state = initialState.genrateOfferPatient,
  action
) => {
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  switch (action.type) {
    case types.GENRATE_OFFER_PATIENT_SEARCH_CALL:
      return { ...state, loading: true, setUpMonthltFromOffer: false };
    case types.GENRATE_OFFER_PATIENT_SEARCH_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case types.GENRATE_OFFER_PATIENT_SEARCH_ERROR:
      return { ...state, error: false, loading: false };
    case types.UPLOAD_DOCUMNET_FINAL_OFFER_CALL:
      return { ...state, loading: true };
    case types.UPLOAD_DOCUMNET_FINAL_OFFER_SUCCESS:
      return { ...state, uploadDocument: action.data, loading: false };
    case types.UPLOAD_DOCUMNET_FINAL_OFFER_ERROR:
    case types.EMPTY_REDUX_STATE:
      return {
        ...state,
        routingNumberData: false,
        sendMoneyData: false,
        merchantListData: false,
        // managerListData: false,
      };
    case types.EMPTY_MERCHANT_RTN_REDUX_STATE:
      return {
        ...state,
        routingNumberData: false,
      };
    case types.EMPTY_MERCHANT_ACC_REDUX_STATE:
      return {
        ...state,
        accountNumberData: false,
      };
    case types.EMPTY_EMAIL_LIST_REDUX_STATE:
      return {
        ...state,
        emailData: false,
        emailListData: false,
      };
    case types.SEND_MONEY_CALL:
      return { ...state, loading: true };
    case types.SEND_MONEY_SUCCESS:
      return { ...state, sendMoneyData: action.data, loading: false };
    case types.SEND_MONEY_ERROR:
      return { ...state, error: false, loading: false };
    case types.SEND_OFFER_EMAIL_CALL:
      return { ...state, loading: true };
    case types.SEND_OFFER_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailOffer: action.data,
        activePlanPatientList: false,
        loading: false,
      };
    case types.SEND_OFFER_EMAIL_ERROR:
      return { ...state, error: false, loading: false };
    case types.SHOW_EMAIL_PATIENT_DETAILS_CALL:
      return { ...state, loading: true };
    case types.SHOW_EMAIL_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        showPatientDetailsViaMail: action.data,
        loading: false,
      };
    case types.SHOW_EMAIL_PATIENT_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.UPDATE_PATIENT_DETAILS_OFFER_CALL:
      return { ...state, loading: true };
    case types.UPDATE_PATIENT_DETAILS_OFFER_SUCCESS:
      return {
        ...state,
        updatePatientDetailsOffer: action.data,
        loading: false,
      };
    case types.UPDATE_PATIENT_DETAILS_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_GENRATE_OFFER_CALL:
      return { ...state, loading: true };
    case types.PATIENT_GENRATE_OFFER_SUCCESS:
      return {
        ...state,
        genrateOfferData: action.data,
        loading: false,
        createOffer: false,
        paymentMakeDetails: false,
        paymentDetailsData: false,
      };
    case types.PATIENT_GENRATE_OFFER_ERROR:
      return { ...state, error: false, loading: false };

    case types.PERSONAL_LOAN_OFFER_CALL:
      return { ...state, loading: true };
    case types.PERSONAL_LOAN_OFFER_SUCCESS:
      return {
        ...state,
        generatedFinanceList: action.data,
        loading: false,
        createOffer: false,
        paymentMakeDetails: false,
        paymentDetailsData: false,
      };
    case types.PERSONAL_LOAN_OFFER_ERROR:
      return { ...state, error: false, loading: false };

    case types.CREATE_OFFER_PATIENT_CALL:
      return { ...state, loading: true };
    case types.CREATE_OFFER_PATIENT_SUCCESS:
      return { ...state, createOffer: action.data, loading: false };
    case types.CREATE_OFFER_PATIENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.PRINT_OFFER_PATIENT_CALL:
      return { ...state, loading: true };
    case types.PRINT_OFFER_PATIENT_SUCCESS:
      return { ...state, printOfferData: action.data, loading: false };
    case types.PRINT_OFFER_PATIENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.MAKE_PATIENT_PAYMENT_CALL:
      return { ...state, loading: true };
    case types.MAKE_PATIENT_PAYMENT_SUCCESS:
      return { ...state, paymentMakeDetails: action.data, loading: false };
    case types.MAKE_PATIENT_PAYMENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.PAYMENT_LINK_VERIFY_CALL:
      return { ...state, paymentVerifyData: action.data, loading: true };
    case types.PAYMENT_LINK_VERIFY_CALL_SUCCESS:
      return { ...state, paymentVerifyData: action.data, loading: false };
    case types.PAYMENT_LINK_VERIFY_CALL_ERROR:
      return { ...state, error: false, loading: false };
    case types.MAKE_PATIENT_CALL:
      return { ...state, paymentCompleteData: action.data, loading: true };
    case types.MAKE_PAYMENT_SUCCESS:
      return { ...state, paymentCompleteData: action.data, loading: false };
    case types.MAKE_PAYMENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.SIGN_UP_CALL:
      return { ...state, signUpData: action.data, loading: true };
    case types.SIGN_UP_SUCCESS:
      return { ...state, signUpData: action.data, loading: false };
    case types.SIGN_UP_ERROR:
      return { ...state, error: false, loading: false };
    case types.SUBMIT_API_CALL:
      return { ...state, submitApiData: action.data, loading: true };
    case types.SUBMIT_API_SUCCESS:
      return { ...state, submitApiData: action.data, loading: false };
    case types.SUBMIT_API_ERROR:
      return { ...state, error: false, loading: false };
    case types.DELETE_PATIENT_OFFER_DOCUMENTS_CALL:
      return { ...state, loading: true };
    case types.DELETE_PATIENT_OFFER_DOCUMENTS_SUCCESS:
      return { ...state, deleteDocument: action.data, loading: false };
    case types.DELETE_PATIENT_OFFER_DOCUMENTS_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_INFO_BILLING_DETAILS_CALL:
      return {
        ...state,
        loading: true,
        paymentDetailsData: false,
        printOfferData: false,
      };
    case types.PATIENT_INFO_BILLING_DETAILS_SUCCESS:
      return { ...state, paymentDetailsData: action.data, loading: false };
    case types.PATIENT_INFO_BILLING_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.SHOW_EMAIL_PATIENT_INFO_CALL:
      return { ...state, loading: true, patientId: action.data.patientId };
    case types.SHOW_EMAIL_PATIENT_INFO_SUCCESS:
      return { ...state, showPatientInfoViaMail: action.data, loading: false };
    case types.SHOW_EMAIL_PATIENT_INFO_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_REGISTER_CALL:
      return { ...state, loading: true };
    case types.PATIENT_REGISTER_SUCCESS:
      return { ...state, patientRegisterData: action.data, loading: false };
    case types.PATIENT_REGISTER_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_OFFER_GRAPH_CALL:
      return { ...state, loading: true };
    case types.PATIENT_OFFER_GRAPH_SUCCESS:
      return { ...state, patientGraphData: action.data, loading: false };
    case types.PATIENT_OFFER_GRAPH_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_OFFER_GRAPH_DATA_EMPTY:
      return { ...state, error: false, patientGraphData: false };
    case types.PATIENT_OFFER_EMAIL_SET:
      return { ...state, error: false, emailState: true };
    case types.PATIENT_FORGET_PASSWORD_CALL:
      return { ...state, loading: true };
    case types.PATIENT_FORGET_PASSWORD_SUCCESS:
      return { ...state, forgetPassword: action.data, loading: false };
    case types.PATIENT_FORGET_PASSWORD_ERROR:
      return { ...state, error: false, loading: false };
    case types.CHECK_RESET_PASSWORD_AUTH_CHECK_CALL:
      return { ...state, loading: true };
    case types.CHECK_RESET_PASSWORD_AUTH_CHECK_SUCCESS:
      return { ...state, authTokenCheck: action.data, loading: false };
    case types.CHECK_RESET_PASSWORD_AUTH_CHECK_ERROR:
      return { ...state, error: false, loading: false };
    case types.CHANGE_PATIENT_PASSWORD_CALL:
      return { ...state, loading: true };
    case types.CHANGE_PATIENT_PASSWORD_SUCCESS:
      return { ...state, changePassword: action.data, loading: false };
    case types.CHANGE_PATIENT_PASSWORD_ERROR:
      return { ...state, error: false, loading: false };
    case types.SERVICES_CPT_CODE_PATIENT_CALL:
      return { ...state, loading: true };
    case types.SERVICES_CPT_CODE_PATIENT_SUCCESS:
      return { ...state, cptCodeData: action.data, loading: false };
    case types.SERVICES_CPT_CODE_PATIENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.ADD_PATIENT_DETAILS_OFFER_CALL:
      return { ...state, loading: true };
    case types.ADD_PATIENT_DETAILS_OFFER_SUCCESS:
      return { ...state, addPatientData: action.data, loading: false };
    case types.ADD_PATIENT_DETAILS_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.UPDATE_PATIENT_DETAILS_CALL:
      return { ...state, loading: true };
    case types.UPDATE_PATIENT_DETAILS_SUCCESS:
      return { ...state, updatePatientDetails: action.data, loading: false };
    case types.UPDATE_PATIENT_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.ACTIVE_PLAN_PATIENT_LIST_CALL:
      return { ...state, loading: true };
    case types.ACTIVE_PLAN_PATIENT_LIST_SUCCESS:
      return { ...state, activePlanPatientList: action.data, loading: false };
    case types.ACTIVE_PLAN_PATIENT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_HYPER_LINK_GENRATE_OFFER_CALL:
      return { ...state, loading: true };
    case types.PATIENT_HYPER_LINK_GENRATE_OFFER_SUCCESS:
      return {
        ...state,
        genrateOfferData: action.data,
        loading: false,
        createOffer: false,
        paymentMakeDetails: false,
        paymentDetailsData: false,
      };
    case types.PATIENT_HYPER_LINK_GENRATE_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_DOCUMENT_LIST_CALL:
      return { ...state, loading: true };
    case types.PATIENT_DOCUMENT_LIST_SUCCESS:
      return { ...state, patientDocumentList: action.data, loading: false };
    case types.PATIENT_DOCUMENT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.OFFER_DOWNLOAD_FILE_CALL:
      return { ...state, loading: true };
    case types.OFFER_DOWNLOAD_FILE_SUCCESS:
      return { ...state, offerDownloadFile: action.data, loading: false };
    case types.OFFER_DOWNLOAD_FILE_ERROR:
      return { ...state, error: false, loading: false };
    case types.ADD_PROPOSAL_DETAILS_CALL:
      return { ...state, loading: true, addProposalData: false };
    case types.ADD_PROPOSAL_DETAILS_SUCCESS:
      return {
        ...state,
        addProposalData: action.data,
        loading: false,
        data: false,
      };
    case types.ADD_PROPOSAL_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.SETUP_MONTHLY_PAYMENT_CALL:
      return { ...state, loading: true };
    case types.SETUP_MONTHLY_PAYMENT_SUCCESS:
      return { ...state, setUpMonthlyPayment: action.data, loading: false };
    case types.SETUP_MONTHLY_PAYMENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.COMPLETED_PLAN_PATIENT_LIST_CALL:
      return { ...state, loading: true };
    case types.COMPLETED_PLAN_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        completedPlanPatientList: action.data,
        loading: false,
      };
    case types.COMPLETED_PLAN_PATIENT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.ROUTING_NUMBER_CALL:
      return { ...state, loading: true };
    case types.ROUTING_NUMBER_SUCCESS:
      const bytes = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const parsedData = JSON.parse(decryptedData);
      return {
        ...state,
        routingNumberData: parsedData,
        loading: false,
      };
    case types.ROUTING_NUMBER_ERROR:
      return { ...state, error: false, loading: false };
    case types.ACCOUNT_NUMBER_CALL:
      return { ...state, loading: true };
    case types.ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        accountNumberData: action.data,
        loading: false,
      };
    case types.ACCOUNT_NUMBER_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_MERCHANT_LIST:
      return { ...state, loading: true };
    case types.GET_MERCHANT_LIST_SUCCESS:
      const bytes1 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData1 = bytes1.toString(CryptoJS.enc.Utf8);
      const parsedData1 = JSON.parse(decryptedData1);
      return {
        ...state,
        merchantListData: {
          ...action.data,
          data: parsedData1,
        },
        loading: false,
      };
    case types.GET_MERCHANT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_MERCHANT_LIST_NAMES:
      return { ...state, loading: true };
    case types.GET_MERCHANT_LIST_NAMES_SUCCESS:
      const bytes2 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData2 = bytes2.toString(CryptoJS.enc.Utf8);
      const parsedData2 = JSON.parse(decryptedData2);
      return {
        ...state,
        merchantListNames: {
          ...action.data,
          data: parsedData2,
        },
        loading: false,
      };
    case types.GET_MERCHANT_LIST_NAMES_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_FORM_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_FORM_DATA:
      return {
        ...state,
        formData: {
          searchBusinessName: "",
          searchDbaName: "",
          searchStatus: null,
          searchStatusValue: "",
          searchManagerName: "",
        },
      };
    case types.GET_MANAGER_LIST:
      return { ...state, loading: true };
    case types.GET_MANAGER_LIST_SUCCESS:
      const bytes4 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData4 = bytes4.toString(CryptoJS.enc.Utf8);
      const parsedData4 = JSON.parse(decryptedData4);
      return {
        ...state,
        managerListData: {
          ...action.data,
          data: parsedData4,
        },
        loading: false,
      };
    case types.GET_MANAGER_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_MANAGER_LIST_BY_ID:
      return { ...state, loading: true };
    case types.GET_MANAGER_LIST_SUCCESS_BY_ID:
      const bytes6 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData6 = bytes6.toString(CryptoJS.enc.Utf8);
      const parsedData6 = JSON.parse(decryptedData6);
      return {
        ...state,
        managerListDataById: {
          ...action.data,
          data: parsedData6,
        },
        loading: false,
      };
    case types.GET_MANAGER_LIST_ERROR_BY_ID:
      return { ...state, error: false, loading: false };
    case types.GET_MANAGER_LIST_NAMES:
      return { ...state, loading: true };
    case types.GET_MANAGER_LIST_NAMES_SUCCESS:
      const bytes5 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData5 = bytes5.toString(CryptoJS.enc.Utf8);
      const parsedData5 = JSON.parse(decryptedData5);
      return {
        ...state,
        managerListNames: {
          ...action.data,
          data: parsedData5,
        },
        loading: false,
      };
    case types.GET_MANAGER_LIST_NAMES_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_MANAGER_ACTIONS_LIST:
      return { ...state, loading: true };
    case types.GET_MANAGER_ACTIONS_LIST_SUCCESS:
      return {
        ...state,
        managerActionsData: action.data,
        loading: false,
      };
    case types.GET_MANAGER_ACTIONS_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_MANAGER_ACTIONS:
      return { ...state, loading: true };
    case types.GET_MANAGER_ACTIONS_SUCCESS:
      return {
        ...state,
        managerActionsLink: action.data,
        loading: false,
      };
    case types.GET_MANAGER_ACTIONS_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_FORM_DATA_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_FORM_DATA_MANAGER:
      return {
        ...state,
        formDataManager: {
          searchBusinessName: "",
          searchManagerUID: "",
          searchEmail: "",
          searchStatus: null,
          searchStatusValue: "",
          searchManagerName: "",
        },
      };
    case types.GET_TRANSACTION_LIST:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_LIST_SUCCESS:
      const bytes3 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedData3 = bytes3.toString(CryptoJS.enc.Utf8);
      const parsedData3 = JSON.parse(decryptedData3);
      return {
        ...state,
        transactionListData: {
          ...action.data,
          data: parsedData3,
        },
        loading: false,
      };
    case types.GET_TRANSACTION_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_TRANSACTION_HISTORY:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistoryData: action.data,
        loading: false,
      };
    case types.GET_TRANSACTION_HISTORY_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_API_LOGS:
      return { ...state, loading: true };
    case types.GET_API_LOGS_SUCCESS:
      const bytesData4 = CryptoJS.AES.decrypt(action.data.data, secretKey);
      const decryptedBytes4 = bytesData4.toString(CryptoJS.enc.Utf8);
      const parsedBytesData = JSON.parse(decryptedBytes4);
      // console.log(parsedBytesData, "parsedBytesData++++++++++++++++++");
      return {
        ...state,
        apiLogsData: {
          ...action.data,
          data: parsedBytesData,
        },
        loading: false,
      };
    // return {
    //   ...state,
    //   apiLogsData: action.data,
    //   loading: false,
    // };
    case types.GET_API_LOGS_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_FORM_DATA_API_LOGS:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_FORM_DATA_API_LOGS:
      return {
        ...state,
        formData: {
          searchApiType: "",
          searchModule: "",
          searchRequestDate: "",
          searchResponseDate: "",
          searchStatus: null,
          searchStatusValue: "",
        },
      };
    case types.GET_ERROR_CODE:
      return { ...state, loading: true };
    case types.GET_ERROR_CODE_SUCCESS:
      return {
        ...state,
        errorCodeData: action.data,
        loading: false,
      };
    case types.GET_ERROR_CODE_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_FORM_DATA_ERROR_CODES:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_FORM_DATA_ERROR_CODES:
      return {
        ...state,
        formDataErrorCodes: {
          searchErrorCode: "",
          searchType: "",
          searchDescription: "",
          searchUpdatedOn: "",
        },
      };
    case types.SETUP_MONTHLY_FROM_OFFER:
      return { ...state, setUpMonthltFromOffer: action.data };
    case types.GENRATE_OFFER_LOADING_FALSE:
      return { ...state, genrateOfferLoading: action.data };
    case types.SET_DEFAULT_VALUE_OFFER:
      return { ...state, defaultValueOffer: action.data };
    case types.SET_PATIENT_OFFER_SELECT_PATINET:
      return { ...state, selectPatient: action.data };
    case types.SET_BACK_TO_SEARCH_FROM_OFFER:
      return { ...state, activePlanPatientList: false };
    case types.SET_PREPOSAL_IDS_ARRAY:
      return { ...state, preposalDataIds: action.data };
    case types.EMPTY_OFFER_SELECT_PATIENT:
      return {
        ...state,
        activePlanPatientList: false,
        completedPlanPatientList: false,
        patientDocumentList: false,
      };
    case types.BEFORE_PAYMENT_SEND_OTP_CALL:
      return { ...state, loading: true };
    case types.BEFORE_PAYMENT_SEND_OTP_SUCCESS:
      return { ...state, sentOTPData: action.data, loading: false };
    case types.BEFORE_PAYMENT_SEND_OTP_ERROR:
      return { ...state, error: false, loading: false };
    case types.VERIFY_PAYMENT_OTP_CALL:
      return { ...state, loading: true };
    case types.VERIFY_PAYMENT_OTP_SUCCESS:
      return { ...state, verifyOTPData: action.data, loading: false };
    case types.VERIFY_PAYMENT_OTP_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_USER_ID_FORGET_PASSWORD:
      return { ...state, userIdForgetPassword: action.data };
    case types.PATIENT_TPO_SCORE_CALL:
      return { ...state, loading: true };
    case types.PATIENT_TPO_SCORE_SUCCESS:
      return { ...state, patientTPOScoreList: action.data, loading: false };
    case types.PATIENT_TPO_SCORE_ERROR:
      return { ...state, error: false, loading: false };
    case types.GENERATE_PATIENT_TPO_SCORE_CALL:
      return { ...state, loading: true };
    case types.GENERATE_PATIENT_TPO_SCORE_SUCCESS:
      return { ...state, generatePatientTPOScore: action.data, loading: false };
    case types.GENERATE_PATIENT_TPO_SCORE_ERROR:
      return { ...state, error: false, loading: false };
    case types.PAYMENT_CHECKOUT_CALL:
      return { ...state, loading: true };
    case types.PAYMENT_CHECKOUT_SUCCESS:
      return { ...state, paymentCheckoutData: action.data, loading: false };
    case types.PAYMENT_CHECKOUT_ERROR:
      return { ...state, error: false, loading: false };
    case types.GLOBAL_PATIENT_SEARCH_CALL:
      return { ...state, loading: true, cernerPatientSearch: false };
    case types.GLOBAL_PATIENT_SEARCH_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case types.GLOBAL_PATIENT_SEARCH_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_FORM_DATA_TRANSACTION_HISTORY:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_FORM_DATA_TRANSACTION_HISTORY:
      return {
        ...state,
        searchDataTransactionHistory: {
          searchTxnID: "",
          searchStartAmount: "",
          searchEndAmount: "",
          searchStartDate: "",
          searchEndDate: "",
          dateRange: false,
          amountRange: false,
        },
      };
    case "SET_TRANSACTION_LIST_PAGE":
      return {
        ...state,
        transactionListPagination: {
          ...state.transactionListPagination,
          currentPage: action.payload,
        },
      };
    case "SET_TRANSACTION_LIST_ROWS_PER_PAGE":
      return {
        ...state,
        transactionListPagination: {
          ...state.transactionListPagination,
          rowsPerPage: action.payload,
        },
      };
    case types.GET_EMAIL_LIST:
      return { ...state, loading: true };
    case types.GET_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        emailListData: action.data,
        loading: false,
      };
    case types.GET_EMAIL_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_EMAIL_DATA:
      return { ...state, loading: true };
    case types.GET_EMAIL_DATA_SUCCESS:
      return {
        ...state,
        emailData: action.data,
        loading: false,
      };
    case types.GET_EMAIL_DATA_ERROR:
      return { ...state, error: false, loading: false };
    case types.GET_EMAIL_DATA1:
      return { ...state, loading: true };
    case types.GET_EMAIL_DATA1_SUCCESS:
      return {
        ...state,
        emailData1: action.data,
        loading: false,
      };
    case types.GET_EMAIL_DATA1_ERROR:
      return { ...state, error: false, loading: false };
    case "SET_MERCHANT_LIST_PAGE":
      return {
        ...state,
        merchantListPagination: {
          ...state.merchantListPagination,
          currentPage: action.payload,
        },
      };
    case "SET_MERCHANT_LIST_ROWS_PER_PAGE":
      return {
        ...state,
        merchantListPagination: {
          ...state.merchantListPagination,
          rowsPerPage: action.payload,
        },
      };
    default:
      return state;
  }
};

export default genrateOfferPatient;
